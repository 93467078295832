<template>
    <div class='grid' v-if='company_id && dd_companies'>
        <Dialog :header="'Modifica il ruolo di ' + editData.email" v-model:visible="edit" >
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <Dropdown v-model="editData.role_id"
                                  id='role_id'
                                  dataKey="value"
                                  :options="dd_b2b_roles"
                                  optionLabel="label"
                                  option-value='value'
                                  :filter="true"
                                  filterPlaceholder="Cerca"/>
                        <label for='role_id'>Ruolo</label>
                    </span>
                </div>

            </div>
            <template #footer>
                <SaveButton @click='changeUseRole(editData)' :load='loading'/>
                <ResetButton @click='closeEdit' :load='loading'/>
            </template>
        </Dialog>
        <Dialog v-model:visible="newUserEdit" >
            <template #header>
                <h6 v-if='editData.id'>Concedi l'accesso a <strong style='color: red'>{{editData.email}}</strong></h6>
                <h6 v-else>Cerca l'utente (deve aver gia fatto accesso a 4books)</h6>
            </template>
            <SearchUser @onChoose='onChose($event)' :resetOnChoose='true' :delay='1300'></SearchUser>
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <Dropdown v-model="editData.role_id"
                                  id='role_id_add'
                                  dataKey="value"
                                  :options="dd_b2b_roles"
                                  optionLabel="label"
                                  option-value='id'
                                  :filter="true"
                                  filterPlaceholder="Cerca"/>
                        <label for='role_id_add'>Ruolo</label>
                    </span>
                </div>

            </div>
            <template #footer>
                <SaveButton  @click='onUserAdd(editData)' :load='loading'/>
                <ResetButton @click='closeAddEditor' :load='loading'/>
            </template>
        </Dialog>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Utenti con accesso CMS</h4>
                </div>
                <DataTable :value='rows'
                           :paginator='true'
                           class='p-datatable-gridlines'
                           :rows='20'
                           dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                           :globalFilterFields="['email', 'last_name', 'first_name']"
                >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            @refresh='refreshList()'
                            @add='openAddEditor'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='id' header='ID' :sortable='true' style='width:4rem'>
                    </Column>

                    <Column field='email' header='Email' :sortable='true' style='min-width:4rem'>
                    </Column>

                    <Column field='last_name' header='Cognome' :sortable='true' style='min-width:4rem'>
                    </Column>

                    <Column field='first_name' header='Nome' :sortable='true' style='min-width:4rem'>
                    </Column>

                    <Column field='roles' header='Ruolo' :sortable='true' style='min-width:4rem'>
                        <template #body='slotProps'>
                            {{splitRoles(slotProps.data.roles)}}
                        </template>
                    </Column>

                    <Column field='id' header='Azioni'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-trash' class='mr-2 mb-2 p-button-danger' title='Elimina'
                                    @click='revokeAccess(slotProps.data)'></Button>
                        </template>
                    </Column>


                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const commonStore = createNamespacedHelpers('Common');
const { mapActions, mapGetters } = createNamespacedHelpers('Users/CompanyDashboardAccess');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import ResetButton from '../../../components/4books/forms/ResetButton';
import SearchUser from '../../../components/4books/SearchUser';
import dd_companies from '../../../mixins/dd_companies';
import dd_b2b_roles from '../../../mixins/dd_b2b_roles';

export default {
    components: {
        ResetButton,
        ListHeader,
        SearchUser
    },
    props: {
        company_id: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            edit: false,
            editData: {
                id: 0,
                role_id: 0,
                email: ''
            },
            newUserEdit: false
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (company) return company.name;
            }
            return "-" + this.company_id;
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'delete', 'changeRole', 'grantAccess']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        splitRoles(roles){
            return roles.join(', ');
        },
        closeEdit(){
            this.edit = false;
            this.editData = {
                id: 0,
                role_id: 0,
                email: ''
            };
        },
        openRoleEditor(data){
            this.edit = true;
            this.editData = data;
        },
        closeAddEditor(){
            this.newUserEdit = false;
            this.editData = {};
        },
        openAddEditor(){
            this.newUserEdit = true;
            this.editData = {};
        },
        refreshList() {
            if (!this.company_id) return;
            this.setPageBreadcrumbs();
            this.fetchList({
                company_id: this.company_id
            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({
                email: ""
            });
            this.addFiltersByStartWithArray(['email', 'last_name', 'first_name']);
        },
        changeUseRole(data){
            this.changeRole({
                ...data,
                company_id: this.company_id
            }).then(() => {
                this.closeEdit();
                this.refreshList();
            }).catch(this.ShowApiError);
        },
        onUserAdd(data){
            if ((!data) || (!data.email)){
                this.ShowWarning("Attenzione", "Non è stato selezionato alcun utente");
                return;
            }
            this.grantAccess({
                ...data,
                company_id: this.company_id
            }).then(() => {
                this.closeAddEditor();
                this.refreshList();
            }).catch(this.ShowApiError);
        },
        onChose(data){
            this.editData = data;
        },
        revokeAccess(data){
            this.$confirm.require({
                message: "Sei sicuro di voler togliere l'accesso alla dashboard B2B a "+data.email+' ?',
                header: 'Operazione non annullabile',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.delete({
                        id: data.id,
                        company_id: this.company_id
                    }).then(() => {
                        this.refreshList();
                    }).catch(this.ShowApiError);
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });

        },
        clearFilter() {
            this.initFilters();
        },
        setPageBreadcrumbs(){
            this.setExtraBreadcrumbs([
                { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                { label: 'Utenti dashboard B2B' }
            ]);
        },
    },
    watch: {
        company_id() {
            this.refreshList();
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        },
        editData(n){
            console.log("CHANGED", n);
        }
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies,
        dd_b2b_roles
    ],
};
</script>
