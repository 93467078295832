import { createNamespacedHelpers } from 'vuex';
const usersStore = createNamespacedHelpers('Users');
import tools from '../libs/tools';

export default {
    mounted(){
        this.getB2BRoles();
    },
    data(){
        return {
            dd_b2b_roles: []
        };
    },
    computed: {
        ...usersStore.mapGetters(['B2BRoles/rows','B2BRoles/loading']),
        rolesRefreshLoadingIcon(){
            return this['B2BRoles/loading'] ? "pi pi-spin pi-spinner" : "pi pi-refresh";
        }
    },
    methods: {
        ...usersStore.mapActions(['B2BRoles/fetchList']),
        initB2BRoles(as){
            this.dd_b2b_roles = tools.models.listsToDropdown(as);
        },
        refreshB2BRoles(){
            this['B2BRoles/fetchList']().then(as => {
                this.initB2BRoles(as);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getB2BRoles(){
            if (this['B2BRoles/rows'] && this['B2BRoles/rows'].length){
                this.initB2BRoles(this['B2BRoles/rows']);
            } else {
                this.refreshB2BRoles();
            }
        }

    }
}
